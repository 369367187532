import React from "react"

import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion"
import Img from "gatsby-image"

export default function IndexPage({data}) {

    const graphData = useStaticQuery(graphql`
        query HomepageQuery {
            site {
                siteMetadata {
                    services {
                        title
                    }
                }
            }
            imageHardwr: file(relativePath: { eq: "people-taking-notes.jpg" }) {
                childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 420, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            imageInfra: file(relativePath: { eq: "people-using-laptops.jpg" }) {
                childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 420, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `);

    const [imageHardwrRef, imageHardwrInView] = useInView({
        triggerOnce: true,
        threshold: 0.5
    });

    const [imageInfraRef, imageInfraInView] = useInView({
        triggerOnce: true,
        threshold: 0.5
    });

    return(
        <>
            <SEO
                title="Network support for all your needs"
            />

            <div className={"mb-10 lg:mb-16 px-6 lg:px-12 xl:px-20 text-center xl:w-3/5 mx-auto"}>
                <h1 className="text-4xl tracking-tight leading-10 font-black text-gray-800 sm:text-5xl sm:leading-none md:text-6xl prose prose-xl mx-auto">
                  Network infrastructure support for all your needs
                </h1>
                <p className="mt-3 sm:mt-5 font-sans lg:text-center prose prose-xl mx-auto">
                  PKD services enrich and enable new business processes, new markets and customer interactions. We can help your business to take advantage of new technology to increase business relevance.
                </p>
            </div>

            <div className="mb-10 md:mb-20 xl:mb-32 mx-auto px-6 md:px-12">
                <div className="text-center">
                    <p className="text-base font-sans leading-6 text-primary font-black tracking-wide uppercase">Services</p>
                    <h2 className={"mt-2 text-3xl font-black leading-8 tracking-tight text-gray-800 sm:text-4xl sm:leading-10"}>How we can help</h2>
                    <p className="mt-4 mb-4 max-w-2xl prose prose-lg text-gray-700 mx-auto">
                        One of the most difficult things for a small business owner or school administrator to do is figure out and implement IT solutions. That’s because you don’t have the experience, knowledge or time necessary to make informed decisions.
                    </p>
                    <p className="mt-4 mb-4 max-w-2xl prose prose-lg text-gray-700 mx-auto">
                        Let the experienced team at PKD advise you on the best technology solutions based upon your organisation's needs and budget. No one-size-fits-all solutions here; we only make recommendations and plans after we understand how you operate.
                    </p>
                </div>
            </div>

            <div className={"mb-10 md:mb-20 xl:mb-32 max-w-screen-xl mx-auto px-6 md:px-12 text-center"}>

                <div className="flex flex-wrap">

                    <div className="mb-6 md:mb-0 w-full md:w-1/2">

                        <div className={"flex h-full justify-center items-center"}>

                            <div className="text-center md:px-12">
                                <p className="text-base font-sans leading-6 text-primary font-black tracking-wide uppercase">What we cover</p>
                                <h2 className={"mt-2 text-3xl font-black leading-8 tracking-tight text-gray-800 sm:text-4xl sm:leading-10"}>IT hardware and support</h2>
                                <ul className="mt-4 mb-4 max-w-2xl prose prose-lg text-gray-700 lg:mx-auto">
                                    {graphData.site.siteMetadata.services.map(link => (
                                        <li className={"mb-1 pkd-list"} key={link.title}>{link.title}</li>
                                    ))}
                                </ul>
                            </div>

                        </div>

                    </div>

                    <div className="mb-10 md:mb-0 w-full md:w-1/2">

                        <motion.div
                            ref={imageHardwrRef}
                            initial={{ x: '100px', opacity: 0}}
                            animate={{ x: imageHardwrInView ? '0px' : '100px', opacity: imageHardwrInView ? 1 : 0 }}
                            transition={{ ease: 'easeOut', duration: 1 }}>
                            <Img fluid={graphData.imageHardwr.childImageSharp.fluid}
                                 alt=""
                                 loading={"lazy"}
                                 className={"mx-auto rounded-lg shadow-lg"}
                            />
                        </motion.div>

                    </div>

                </div>

            </div>

            <div className={"mb-10 md:mb-20 xl:mb-32 max-w-screen-xl mx-auto px-6 md:px-12 text-center"}>

                <div className="flex flex-wrap flex-col-reverse md:flex-row">

                    <div className="mb-10 md:mb-0 w-full md:w-1/2">

                        <motion.div
                            ref={imageInfraRef}
                            initial={{ x: '-100px', opacity: 0}}
                            animate={{ x: imageInfraInView ? '0px' : '-100px', opacity: imageInfraInView ? 1 : 0 }}
                            transition={{ ease: 'easeOut', duration: 1 }}>
                            <Img fluid={graphData.imageInfra.childImageSharp.fluid}
                                 alt=""
                                 loading={"lazy"}
                                 className={"mx-auto rounded-lg shadow-lg"}
                            />
                        </motion.div>

                    </div>

                    <div className="mb-10 md:mb-0 w-full overflow-hidden md:w-1/2">

                        <div className={"flex h-full justify-center items-center"}>

                            <div className="text-center md:px-12">
                                <p className="text-base font-sans leading-6 text-primary font-black tracking-wide uppercase">What we cover</p>
                                <h2 className={"mt-2 text-3xl font-black leading-8 tracking-tight text-gray-800 sm:text-4xl sm:leading-10"}>Infrastructure</h2>
                                <ul className="mt-4 mb-4 max-w-2xl prose prose-lg text-gray-700 lg:mx-auto">
                                    <li className={"mb-1 pkd-list"}>Collaboration solutions can more efficiently bring people and information together, leading to increased employee and team productivity, simplified business processes, and improved workflow.</li>
                                    <li className={"mb-1 pkd-list"}>Provide a highly secure experience across devices, locations and organisations to improve your communications, relations and productivity.</li>
                                    <li className={"mb-1 pkd-list"}>Improve innovation and speed time to market, by creating an infrastructure, data centre and cloud environment that enables the business to reach their limitless potential.</li>
                                </ul>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}
